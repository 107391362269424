import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Link from '@activebrands/core-web/components/Link';
import Slider from '@activebrands/core-web/components/Slider';
import createSlider from '@activebrands/core-web/libs/slider/create-slider';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowButton from 'components/buttons/ArrowButton';
import ThemeButton from 'components/buttons/ThemeButton';

const Wrapper = styled('div', {
    position: 'relative',
    height: '310px',
    marginTop: '48px',
});

const Points = styled('span', {
    marginRight: '8px',
    color: 'var(--color-text-act-module)',
});

const BonusVoucherContainer = styled('div', {
    display: ['block', null, null, null, 'flex'],
    justifyContent: 'space-between',
    overflow: 'hidden',
});

const Vouchers = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: ['100%', null, '50%'],
    minWidth: '300px',
});

const Heading = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const VoucherCard = styled('div', {
    alignItems: 'center',
    marginTop: '18px',
    backgroundColor: 'var(--color-bg-act-vouchers)',
    borderRadius: '8px',
    padding: '16px',
});

const VoucherInfo = styled('div', {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '12px',
    color: 'var(--color-palette-default-grey)',
    ...textStyles['Miscellaneous/12_100'],
});

const VoucherAmount = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
});

const LinkButton = styled('div', {
    display: 'flex',
    alignItems: 'end',
    marginTop: '16px',
});

const ContentWrapper = styled('div', {
    padding: ['12px', null, null, null, '24px'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
});

const SliderNav = ({ slider }) => {
    const [css] = useStyletron();
    const iconStyling = { margin: 0 };
    return (
        <div className={css({ display: 'flex', cursor: 'pointer' })}>
            <div onClick={() => slider.slideToPrev()}>
                <ArrowButton
                    $style={{
                        width: '40px',
                        height: '40px',
                        marginRight: '8px',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                    iconStyling={iconStyling}
                    state="arrow-left"
                    theme="inverted"
                />
            </div>
            <div onClick={() => slider.slideToNext()}>
                <ArrowButton
                    $style={{
                        width: '40px',
                        height: '40px',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                    iconStyling={iconStyling}
                    state="arrow-right"
                    theme="inverted"
                />
            </div>
        </div>
    );
};

const ActRewardsSummary = ({ background = {}, buttons = {}, label = '' }) => {
    const [css] = useStyletron();
    const slider = createSlider();
    const user = useSelector(state => state.auth.user);
    const vouchers = user?.extendedProperties?.voyadoPromotions;
    const notRedeemedVouchers = vouchers?.filter(bonusCheck => !bonusCheck.redeemed);

    return (
        <Wrapper>
            <BackgroundDynamic
                $style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    padding: '16px',
                    position: 'absolute',
                }}
                background={background}
            />
            <ContentWrapper>
                <Points>
                    <span className={css(textStyles['Primary/64_94_-2_700'])}>{user.bonusPoints}</span>
                    <span className={css(textStyles['Secondary/14_130_400'])}>{fm('ACT points')}</span>
                </Points>
                <div
                    className={css({
                        display: ['block', null, 'flex'],
                        justifyContent: !!vouchers?.length ? 'space-between' : 'flex-end',
                        width: '100%',
                        zIndex: 1,
                    })}
                >
                    {!!notRedeemedVouchers?.length && (
                        <BonusVoucherContainer>
                            <Vouchers>
                                <div className={css({ display: 'flex', justifyContent: 'space-between' })}>
                                    <Heading>
                                        <span
                                            className={css({
                                                ...textStyles['Primary/24_94_-1_700'],
                                                color: 'var(--color-text-act-module)',
                                            })}
                                        >
                                            {label}
                                        </span>
                                    </Heading>
                                    <SliderNav slider={slider} />
                                </div>
                                <Slider
                                    isDraggable
                                    $style={{ height: '100%' }}
                                    isInfinite={false}
                                    slider={slider}
                                    slidesPerView={1}
                                    snapAlign="none"
                                    spaceBetween="4px"
                                >
                                    {notRedeemedVouchers.map(voucher => {
                                        return (
                                            <VoucherCard key={voucher.id}>
                                                <VoucherAmount>
                                                    <span className={css(textStyles['Primary/32_94_-2_700'])}>
                                                        {voucher.name}
                                                    </span>
                                                    <span className={css(textStyles['Misc/16_100_-1_500_UC'])}>
                                                        {fm('Bonus vouchers')}
                                                    </span>
                                                </VoucherAmount>
                                                <VoucherInfo>
                                                    <span className={css(textStyles['Secondary/12_130_400'])}>
                                                        {voucher.expiresOn}
                                                    </span>
                                                </VoucherInfo>
                                            </VoucherCard>
                                        );
                                    })}
                                </Slider>
                            </Vouchers>
                        </BonusVoucherContainer>
                    )}
                    {buttons.map((button, index) => (
                        <LinkButton key={index}>
                            <ThemeButton
                                as={Link}
                                fontKeys="Misc/14_100_-1_500_UC"
                                rel={button.rel}
                                theme={button.theme}
                                to={button.url}
                            >
                                {button.label}
                            </ThemeButton>
                        </LinkButton>
                    ))}
                </div>
            </ContentWrapper>
        </Wrapper>
    );
};

SliderNav.propTypes = {
    slider: PropTypes.shape({
        slideToNext: PropTypes.func,
        slideToPrev: PropTypes.func,
    }),
};

ActRewardsSummary.propTypes = {
    background: PropTypes.object,
    buttons: PropTypes.array,
    label: PropTypes.string,
};

export default ActRewardsSummary;
